body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-family: Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-calendar-picker-indicator {
  display: none;
}

::-webkit-scrollbar {
  width: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input {
  font-family: Regular;
}

textarea {
  font-family: Regular;
}

@font-face {
  font-family: Bold;
  src: url('./assets/fonts/Poppins-SemiBold.ttf');
  font-style: normal;
}

@font-face {
  font-family: Regular;
  src: url('./assets/fonts/Poppins-Medium.ttf');
  font-style: normal;
}

.money_button {
  width: 3vw;
  height: 3vw;
  cursor: pointer;
  border-radius: 0.5vw;
  border: none;
  transition: 0.2s;
}

.money_button.yandex {
  background: url('https://avatars.mds.yandex.net/get-pdb/1889030/f9ce64ef-f0cb-41d9-b651-7be193dc4a62/orig');
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
  background-color: rgba(255, 255, 255, 1);
}

.money_button.qiwi {
  background: url('https://corp.qiwi.com/dam/jcr:fbce4856-723e-44a2-a54f-e7b164785f01/qiwi_sign_rgb.png');
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
  background-color: rgba(255, 255, 255, 1);
  margin: 0 0 0 0.3vw;
}

@media (min-width: 320px) and (max-width: 480px) {
  .money_button {
    width: 13vw;
    height: 13vw;
    border-radius: 2.5vw;
  }
  .money_button.qiwi {
    margin: 0 0 0 0.6vw;
  }
}

.active {
  opacity: 1;
}

.unactive {
  opacity: 0.5;
}

.my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px;
  /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 30px;
  /* gutter size */
  background-clip: padding-box;
}

/* Style your items */

.my-masonry-grid_column>div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}

.editable {
  position: relative;
  border: 1px solid gray;
  padding: 3px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 2px inset;
}

.editable>input {
  position: relative;
  z-index: 1;
  border: none;
  background-color: transparent;
  box-shadow: none;
  width: 100%;
}

.editable::after {
  position: absolute;
  left: 4px;
  top: 5px;
  content: attr(data-placeholder);
  pointer-events: none;
  opacity: 0.5;
  z-index: 1;
}

a {
  text-decoration: none;
  transition: 0.2s;
}

a:visited {
  text-decoration: none;
  color:#578AD6;
}

a:hover { opacity: 0.8; }