body {}

.heart {
    animation: beat .7s infinite alternate;
}

@keyframes beat {
    to {
        transform: scale(1.2);
    }
}

p {
    color: #767676 !important;
    width: 825px !important;
    font-size: 16px !important;
    line-height: 26px !important;
}

h1, h2, h3 {
    color: #374A5E !important;
    width: 825px !important;
    font-size: 48px !important;
    line-height: 62px !important;
}

/* img {
    width: 825px !important;
} */

/* iframe {
    width: 825px !important;
} */